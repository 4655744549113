import { useMemo } from "react";
import type { Expression, FuseResult } from "fuse.js";
import { ANALYSIS_CONSTANTS } from "@app/analysis/state/analysisConfiguration.constants";
import type { IAnalysisFullMetadata } from "@app/store/staticData/state/staticData.types";
import type { IFilters } from "@common/components/createAnalysisHelp/components/";
import { MIN_QUERY_LENGTH } from "@common/components/createAnalysisHelp/createAnalysisHelp.constants";
import {
    concatIntersectingTuples,
    formExactMatchFilterExpression,
    getHighlightedMatchedAnalyses,
    getYearsArray,
} from "@common/components/createAnalysisHelp/createAnalysisHelp.helpers";
import type { TCollectionItem } from "@common/components/createAnalysisHelp/createAnalysisHelp.types";
import type { TAnalysisType } from "@common/constants/analysis.constants";
import { ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { useFuzzySearch } from "@common/hooks/useFuzzySearch";

const KEYS: (keyof TCollectionItem)[] = [
    "title",
    "description",
    "keywords",
    "metrics",
    "dataYears",
    "travelModes",
    "countries",
];

const PROP_NAMES_TO_HIGHLIGHT: (keyof TCollectionItem)[] = ["title", "description"];

const PROP_NAMES_FOR_INPUT_SEARCH: (keyof TCollectionItem)[] = [
    ...PROP_NAMES_TO_HIGHLIGHT,
    "keywords",
];

const SORTED_ANALYSES_OPTIONS = [...ANALYSIS_CONSTANTS.analysisTypeHelp.options].sort((a, b) =>
    a.title.localeCompare(b.title),
);

export const useFuzzyFilteredAnalysesData = (
    availableAnalysesMetadata: Record<TAnalysisType["id"], IAnalysisFullMetadata>,
    filters: IFilters,
    search: string,
) => {
    const travelModes = useMemo(
        // @ts-ignore Property 'travelMode'|'travelModes' does not exist on type 'IFilters'
        () => (filters.travelMode ? [filters.travelMode] : filters.travelModes || []),
        // @ts-ignore Property 'travelMode'|'travelModes' does not exist on type 'IFilters'
        [filters.travelMode, filters.travelModes],
    );
    // @ts-ignore Property 'country' does not exist on type 'IFilters'
    const countries = useMemo(() => (filters.country ? [filters.country] : []), [filters.country]);

    // Sort analyses in alphabetical order
    const sortedAnalysesCollection = useMemo<TCollectionItem[]>(() => {
        const sortedSelectedTravelModesData = {} as Record<string, TCollectionItem>;

        for (const option of SORTED_ANALYSES_OPTIONS) {
            const { code: analysisCode } = option;

            const getEnrichedWithOptionPropsMetadata = (
                availableMetadata: IAnalysisFullMetadata,
            ): TCollectionItem => ({
                ...option,
                ...availableMetadata,
            });

            if (availableAnalysesMetadata[analysisCode]) {
                sortedSelectedTravelModesData[analysisCode] = getEnrichedWithOptionPropsMetadata(
                    availableAnalysesMetadata[analysisCode],
                );
            } else if (analysisCode === "aadt" && availableAnalysesMetadata.AADT) {
                sortedSelectedTravelModesData[analysisCode] = getEnrichedWithOptionPropsMetadata(
                    availableAnalysesMetadata.AADT,
                );
            }
        }

        return Object.values(sortedSelectedTravelModesData);
    }, [availableAnalysesMetadata]);

    const yearsFilterArray = useMemo(() => {
        return filters.dateRange ? getYearsArray(filters.dateRange) : filters.dataYears;
    }, [filters]);

    const searchExpression: Expression | null = useMemo(() => {
        const searchFilterExpression: Record<string, string>[] = search
            ? [{ title: search }, { description: search }, { keywords: search }]
            : [];

        const expression = [
            ...searchFilterExpression,
            ...formExactMatchFilterExpression(filters.metrics, "metrics"),
            ...formExactMatchFilterExpression(yearsFilterArray, "dataYears"),
            ...formExactMatchFilterExpression(travelModes, "travelModes"),
            ...formExactMatchFilterExpression(countries, "countries"),
        ];

        // If no filters are present than no search should be applied, thus set null
        return expression.length
            ? {
                  $or: expression,
              }
            : null;
    }, [yearsFilterArray, travelModes, countries, filters.metrics, search]);

    // All matched analyses
    const matchedAnalysesFuseResult = useFuzzySearch({
        keys: KEYS,
        searchExpression,
        collection: sortedAnalysesCollection,
        options: {
            threshold: 0.3,
            ignoreFieldNorm: true,
            useExtendedSearch: true,
        },
    });

    // Expression to search using input search string filter
    const inputSearchExpression = useMemo(() => {
        return search
            ? {
                  $or: PROP_NAMES_FOR_INPUT_SEARCH.map(propName => ({
                      $path: propName,
                      $val: `'${search}`,
                  })),
              }
            : null;
    }, [search]);

    // Matched analyses in which keywords from PROP_NAMES_FOR_INPUT_SEARCH were found with inputSearchExpression
    const exactlyMatchedInputSearchResults = useFuzzySearch({
        keys: PROP_NAMES_FOR_INPUT_SEARCH,
        collection: sortedAnalysesCollection,
        searchExpression: inputSearchExpression,
        options: {
            minMatchCharLength: 2,
            ignoreFieldNorm: true,
            useExtendedSearch: true,
        },
    });

    // Matched and highlighted results;
    // analyses data is taken from highlightedMatchedAnalyses array,
    // information on matches (such as each match keys and indices) is taken from more strictly fuse searched exactlyMatchedInputSearchResults
    const matchedFuseResultsWithDataForHighlight = useMemo(() => {
        const exactlyMatchedInputSearchResultsMap = new Map(
            exactlyMatchedInputSearchResults.map(data => [data.item.code, data]),
        );

        return matchedAnalysesFuseResult.map(data => {
            return {
                ...data,
                matches: [
                    ...(data.matches?.filter(
                        match =>
                            !PROP_NAMES_TO_HIGHLIGHT.includes(match.key as keyof TCollectionItem),
                    ) ?? []),
                    ...(exactlyMatchedInputSearchResultsMap.get(data.item.code)?.matches ?? []),
                ],
            };
        });
    }, [matchedAnalysesFuseResult, exactlyMatchedInputSearchResults]);

    const highlightedMatchedAnalyses = useMemo(
        () =>
            getHighlightedMatchedAnalyses(
                matchedFuseResultsWithDataForHighlight,
                PROP_NAMES_TO_HIGHLIGHT,
                "highlight-wrapper",
            ),
        [matchedFuseResultsWithDataForHighlight],
    );

    const {
        fullyMatchedAnalyses,
        partiallyMatchedAnalyses,
    }: { fullyMatchedAnalyses: TCollectionItem[]; partiallyMatchedAnalyses: TCollectionItem[] } =
        useMemo(() => {
            if (!matchedFuseResultsWithDataForHighlight?.length)
                return { fullyMatchedAnalyses: [], partiallyMatchedAnalyses: [] };

            const _fullyMatchedAnalyses: FuseResult<TCollectionItem>[] = [];
            const _partiallyMatchedAnalyses: FuseResult<TCollectionItem>[] = [];

            matchedFuseResultsWithDataForHighlight.forEach(result => {
                const item = result.item;
                const matches = result.matches || [];

                const matchedValues: { [key: string]: string[] } = {};

                matches.forEach(match => {
                    const key = match.key as keyof TCollectionItem;

                    match.indices = concatIntersectingTuples(match.indices);

                    matchedValues[key] = matchedValues[key] || [];

                    match.indices.forEach(([start, end]) => {
                        const value = (item[key] as string).slice(start, end + 1);

                        matchedValues[key].push(value);
                    });
                });

                // Function to check which search values are NOT matched
                const getUnmatchedFilterValuesByKey = (
                    key: keyof TCollectionItem,
                    values: string[],
                ): string[] => {
                    const matched = matchedValues[key] || [];

                    return values.filter(value => !matched.includes(value));
                };

                const missingFilterValues: string[] = [];

                missingFilterValues.push(
                    ...getUnmatchedFilterValuesByKey("metrics", filters.metrics || []),
                );
                missingFilterValues.push(
                    ...getUnmatchedFilterValuesByKey("dataYears", yearsFilterArray || []),
                );
                missingFilterValues.push(
                    ...getUnmatchedFilterValuesByKey("travelModes", travelModes),
                );
                missingFilterValues.push(
                    ...getUnmatchedFilterValuesByKey("countries", countries).map(
                        countryKey => ORG_COUNTRIES[countryKey as keyof typeof ORG_COUNTRIES].name,
                    ),
                );

                if (search.length >= MIN_QUERY_LENGTH) {
                    const hasNoneInputSearchMatches = !PROP_NAMES_FOR_INPUT_SEARCH.some(
                        matchedFilterKey => Object.keys(matchedValues).includes(matchedFilterKey),
                    );

                    if (hasNoneInputSearchMatches) {
                        missingFilterValues.push(search);
                    }
                }

                if (!missingFilterValues.length) {
                    _fullyMatchedAnalyses.push(result);
                } else {
                    // Avoid mutating matchedFuseResultsWithDataForHighlight array items
                    const _item = { ...item, missingFilterValues };
                    const _result = { ...result, item: _item };

                    _partiallyMatchedAnalyses.push(_result);
                }
            });

            return {
                fullyMatchedAnalyses: getHighlightedMatchedAnalyses(
                    _fullyMatchedAnalyses,
                    PROP_NAMES_TO_HIGHLIGHT,
                    "highlight-wrapper",
                ),
                partiallyMatchedAnalyses: getHighlightedMatchedAnalyses(
                    _partiallyMatchedAnalyses,
                    PROP_NAMES_TO_HIGHLIGHT,
                    "highlight-wrapper",
                ),
            };
        }, [
            matchedFuseResultsWithDataForHighlight,
            travelModes,
            countries,
            filters.metrics,
            yearsFilterArray,
            search,
        ]);

    return {
        sortedAnalysesCollection,
        fullyMatchedAnalyses,
        partiallyMatchedAnalyses,
        highlightedMatchedAnalyses,
    };
};
