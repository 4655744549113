import { FuseResult, RangeTuple } from "fuse.js";
import moment from "moment";
import { IDateRange } from "@common/components/dateRangeFilter/dateRangeFilter";
import { TAnalysisType } from "@common/constants/analysis.constants";
import { IExtendedFilters } from "./components";
import {
    ALL_VEHICLES_TRAVEL_MODES_CODES,
    TRAVEL_MODES_OPTIONS,
} from "./createAnalysisHelp.constants";
import { INarrowFiltersData, TAnalysisInfoMetadata } from "./createAnalysisHelp.types";

export const getYearsArray = ({ startDate, endDate }: IDateRange) => {
    const startYear = moment(startDate).year();
    const endYear = moment(endDate).year();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
    }

    return years;
};

export const concatIntersectingTuples = (_arr: readonly [number, number][]) => {
    if (_arr.length === 0) return [];

    //@ts-ignore Property 'toSorted' does not exist on type 'readonly [number, number][]'
    const arr = _arr.toSorted((a, b) => a[0] - b[0]);

    const merged: [number, number][] = [];
    let currentInterval = arr[0];

    for (let i = 1; i < arr.length; i++) {
        const [currentStart, currentEnd] = currentInterval;
        const [nextStart, nextEnd] = arr[i];

        if (nextStart <= currentEnd + 1) {
            // If the intervals overlap or are adjacent, merge them
            currentInterval = [currentStart, Math.max(currentEnd, nextEnd)];
        } else {
            // If they don't overlap, push the current interval and start a new one
            merged.push(currentInterval);
            currentInterval = arr[i];
        }
    }

    // Push the last interval
    merged.push(currentInterval);

    return merged;
};

const splitAndHighlight = (
    text: string,
    matches: readonly RangeTuple[],
    className: string,
): React.ReactNode => {
    const parts = [];
    let lastIndex = 0;

    matches.forEach(([start, end]) => {
        if (lastIndex < start) {
            parts.push(text.slice(lastIndex, start));
        }

        parts.push(
            <span key={start} className={className}>
                {text.slice(start, end + 1)}
            </span>,
        );
        lastIndex = end + 1;
    });

    if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
    }

    return parts;
};

export const getHighlightedMatchedAnalyses = <
    T extends {
        [key: string]: any;
    },
>(
    matchedAnalysesFuseResult: FuseResult<T>[],
    keys: (keyof T)[],
    className: string,
): T[] => {
    return matchedAnalysesFuseResult.map(({ item, matches }) => {
        if (!matches) {
            return item;
        }

        const matchIndicesArray = keys.map(key => matches!.find(match => match.key === key));

        const highlightedProps = matchIndicesArray.reduce((res, match) => {
            if (match) {
                const key = match.key as keyof T;
                res[key] = splitAndHighlight(item[key] as string, match.indices, className);
            }
            return res;
        }, {} as { [Property in keyof T]?: React.ReactNode });

        return {
            ...item,
            ...highlightedProps,
        };
    });
};

export const formExactMatchFilterExpression = (data: string[] | number[] | null, path: string) =>
    data
        ? data.map(value => ({
              $path: path,
              $val: `'"${value}"`,
          }))
        : [];

export const getAadtFeatureNameByYear = (year: number) => `Estimated_${year}_AADT`;

export const getFilteredAnalysisInfo = (
    filters: Pick<IExtendedFilters, "country" | "travelMode">,
    analysisInfo?: TAnalysisInfoMetadata[],
): TAnalysisInfoMetadata[] | undefined => {
    if (!analysisInfo) return undefined;

    return analysisInfo.filter(info => {
        const isValidCountry = filters.country === info.country;

        const isValidTravelMode =
            filters.travelMode === TRAVEL_MODES_OPTIONS.All_Vehicles.value
                ? ALL_VEHICLES_TRAVEL_MODES_CODES.includes(info.travel_mode)
                : filters.travelMode === info.travel_mode;

        return isValidCountry && isValidTravelMode;
    });
};

export const getAnalysisInfoCombinedByTravelMode = (analysisInfo?: TAnalysisInfoMetadata[]) => {
    if (!analysisInfo) return undefined;

    const uniqueAnalysesInfo: Record<
        TAnalysisInfoMetadata["travel_mode"],
        TAnalysisInfoMetadata[]
    > = {};

    analysisInfo.forEach(info => {
        if (!ALL_VEHICLES_TRAVEL_MODES_CODES.includes(info.travel_mode)) {
            uniqueAnalysesInfo[info.travel_mode] = [info];
            return;
        }

        if (!uniqueAnalysesInfo[TRAVEL_MODES_OPTIONS.All_Vehicles.value]) {
            uniqueAnalysesInfo[TRAVEL_MODES_OPTIONS.All_Vehicles.value] = [info];
            return;
        }

        const isUniqueInfo = !uniqueAnalysesInfo[TRAVEL_MODES_OPTIONS.All_Vehicles.value].find(
            _info => {
                if (info.country !== _info.country) return false;
                if (info.data_years.toString() !== _info.data_years.toString()) return false;
                if (info.geography.toString() !== _info.geography.toString()) return false;
                if (info.granularity.toString() !== _info.granularity.toString()) return false;
                if (info.key_metrics.toString() !== _info.key_metrics.toString()) return false;

                return true;
            },
        );

        if (isUniqueInfo) {
            uniqueAnalysesInfo[TRAVEL_MODES_OPTIONS.All_Vehicles.value].push(info);
        }
    });

    return Object.values(uniqueAnalysesInfo).flat();
};

/**
 * Combine analyses info from all time periods (YoY + Recent), data_providers and data_source_categories
 * and filter out unselected countries and travelModes
 */
export const getCombinedAnalysesInfo = (
    analysesInfo: INarrowFiltersData["analysis_info"],
    country: IExtendedFilters["country"],
    travelMode: IExtendedFilters["travelMode"],
) => {
    return Object.keys(analysesInfo).reduce((acc, timePeriodKey, idx, timePeriodKeysArr) => {
        const combinedAnalysesInfo = {
            ...acc,
        } as Record<TAnalysisType["id"], TAnalysisInfoMetadata[]>;

        const timePeriodData = analysesInfo[timePeriodKey as keyof typeof analysesInfo];
        const isLastTimePeriod = idx === timePeriodKeysArr.length - 1;

        Object.keys(timePeriodData).forEach(analysisKey => {
            if (!combinedAnalysesInfo[analysisKey]) {
                combinedAnalysesInfo[analysisKey] = [];
            }

            const filteredAnalysesInfo =
                getFilteredAnalysisInfo({ country, travelMode }, timePeriodData[analysisKey]) ||
                [];

            // Combine filtered analysis info from all time periods (YoY + Recent)
            combinedAnalysesInfo[analysisKey] = [
                ...combinedAnalysesInfo[analysisKey],
                ...filteredAnalysesInfo,
            ].reduce((_analysisInfoAcc, _analysisInfo) => {
                if (_analysisInfoAcc.length === 0) {
                    _analysisInfoAcc.push({
                        ..._analysisInfo,
                        // Prevents mutation of the original array during sorting later
                        data_years: [..._analysisInfo.data_years],
                    });
                }

                // Combine data_years
                _analysisInfo.data_years.forEach(year => {
                    if (_analysisInfoAcc[0].data_years.includes(year)) return;
                    _analysisInfoAcc[0].data_years = [..._analysisInfoAcc[0].data_years, year];
                });

                // Combine geography
                _analysisInfo.geography.forEach(geo => {
                    if (_analysisInfoAcc[0].geography.includes(geo)) return;
                    _analysisInfoAcc[0].geography = [..._analysisInfoAcc[0].geography, geo];
                });

                // Combine granularity
                _analysisInfo.granularity.forEach(gran => {
                    if (_analysisInfoAcc[0].granularity.includes(gran)) return;
                    _analysisInfoAcc[0].granularity = [..._analysisInfoAcc[0].granularity, gran];
                });

                // Combine key_metrics
                _analysisInfo.key_metrics.forEach(metric => {
                    if (_analysisInfoAcc[0].key_metrics.includes(metric)) return;
                    _analysisInfoAcc[0].key_metrics = [..._analysisInfoAcc[0].key_metrics, metric];
                });

                // Sort data_years
                if (isLastTimePeriod) {
                    _analysisInfoAcc[0].data_years.sort();
                }

                return _analysisInfoAcc;
            }, [] as TAnalysisInfoMetadata[]);
        });

        return combinedAnalysesInfo;
    }, {} as Record<TAnalysisType["id"], TAnalysisInfoMetadata[]>);
};
