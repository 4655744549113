import type { TRootState } from "@app/store";
import { DEFAULT_THEME } from "@common/constants/application.constants";
import { shouldUseTheme } from "@common/utils/theme";

export const getUserPreferences = (state: TRootState) => state.userPreferences;

export const getZonesManagePreferences = (state: TRootState) =>
    getUserPreferences(state).zonesManage;

export const getApplicationPreferences = (state: TRootState) =>
    getUserPreferences(state).application;

export const getAdminAnalysesPreferences = (state: TRootState) =>
    getUserPreferences(state).adminAnalyses;

export const getAdminFlaggedProjectsPreferences = (state: TRootState) =>
    getUserPreferences(state).adminFlaggedProjects;

export const getAdminZonesPreferences = (state: TRootState) =>
    getUserPreferences(state).adminZones;

export const getStudyManagementPreferences = (state: TRootState) =>
    getUserPreferences(state).studyManagement;

export const getAdminOrgsPreferences = (state: TRootState) => getUserPreferences(state).adminOrgs;

export const getAdminUserOrgsPreferences = (state: TRootState) =>
    getUserPreferences(state).adminUserOrgs;

export const getAdminStudiesPreferences = (state: TRootState) =>
    getUserPreferences(state).adminStudies;

export const getAdminUsersPreferences = (state: TRootState) =>
    getUserPreferences(state).adminUsers;

export const getAdminOrgUsersPreferences = (state: TRootState) =>
    getUserPreferences(state).adminOrgUsers;

export const getAdminSSODomainsPreferences = (state: TRootState) =>
    getUserPreferences(state).adminSSODomains;

export const getAdminMAAPIRequestsPreferences = (state: TRootState) =>
    getUserPreferences(state).adminMAAPIRequests;

export const getProjectsPreferences = (state: TRootState) => getUserPreferences(state).projects;

export const getProjectFoldersPreferences = (state: TRootState) =>
    getUserPreferences(state).projectFolders;

export const getVizAnalysesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).vizAnalysesTable;

export const getVizZonesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).vizZonesTable;

export const getDashboardAnalysesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardAnalysesTable;

export const getDashboardAnalysesTabMapAnalysesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardAnalysesTabMapAnalysesTable;

export const getDashboardOrgsTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardOrgsTable;

export const getDashboardStudiesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardStudiesTable;

export const getDashboardSubscriptionsTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardSubscriptionsTable;

export const getDashboardUsersTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardUsersTable;

export const getDashboardUserAnalysesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardUserAnalysesTable;

export const getDashboardUserOrgsTablePreferences = (state: TRootState) =>
    getUserPreferences(state).dashboardUserOrgsTable;

export const getZoneSetModalTablePreferences = (state: TRootState) =>
    getUserPreferences(state).zoneSetModalTable;

export const getImportFromAnalysisTablePreferences = (state: TRootState) =>
    getUserPreferences(state).importFromAnalysisTable;

export const getReuseZoneSetsTablePreferences = (state: TRootState) =>
    getUserPreferences(state).reuseZoneSetsTable;

export const getReuseAnalysesTablePreferences = (state: TRootState) =>
    getUserPreferences(state).reuseAnalysesTable;

export const getTheme = (state: TRootState) =>
    (shouldUseTheme() && getApplicationPreferences(state)?.theme) || DEFAULT_THEME;

export const getMapStyle = (state: TRootState) => getApplicationPreferences(state)?.mapStyle;

export const getPreferredCountry = (state: TRootState) => getApplicationPreferences(state).country;

export const getPreferredWeekdayType = (state: TRootState) =>
    getApplicationPreferences(state).weekdayType;

export const getSessionPreferences = (state: TRootState) =>
    getUserPreferences(state).sessionPreferences;

export const getOsmLayersCategories = (state: TRootState) =>
    getApplicationPreferences(state).mapLayersSettings.osmLayersCategories;

export const getEnhancedLayersCategories = (state: TRootState) =>
    getApplicationPreferences(state).mapLayersSettings.enhancedLayersCategories;

export const getCreateAnalysisHelpPreferences = (state: TRootState) =>
    getApplicationPreferences(state).createAnalysisHelp;
