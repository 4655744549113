import { useMemo } from "react";
import { components } from "react-select";
import { Components } from "react-select/src/components";
import type { TTravelMode } from "@app/analysis/state/analysisConfiguration.constants";
import { TRAVEL_MODES } from "@app/analysis/state/analysisConfiguration.constants";
import { StlToggleButton, StlToggleButtonGroup } from "@common/components";
import { ISelectOption } from "@common/components/createAnalysisHelp/createAnalysisHelp.types";
import { StlSelect } from "@common/components/select/select";
import { TOrgCountryKey } from "@common/constants/analysis.constants";
import {
    faBicycle,
    faBus,
    faCar,
    faCaretDown,
    faPersonWalking,
    faTrain,
    faTruck,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./topMenuFilters.less";

const TRAVEL_MODE_ICONS: Record<TTravelMode["code"], IconDefinition> = {
    [TRAVEL_MODES.ALL_VEHICLES.code]: faCar,
    [TRAVEL_MODES.BICYCLE.code]: faBicycle,
    [TRAVEL_MODES.BUS.code]: faBus,
    [TRAVEL_MODES.PEDESTRIAN.code]: faPersonWalking,
    [TRAVEL_MODES.RAIL.code]: faTrain,
    [TRAVEL_MODES.TRUCK.code]: faTruck,
};

export interface ITopMenuFilters {
    travelMode: TTravelMode["code"] | null;
    country: TOrgCountryKey | null;
}

const DropdownIndicator: Components["DropdownIndicator"] = props => {
    const innerProps = {
        "data-testid": "dropdownIndicator",
    };

    return (
        <components.DropdownIndicator {...props} innerProps={innerProps}>
            <FontAwesomeIcon icon={faCaretDown} />
        </components.DropdownIndicator>
    );
};

type TProps = {
    filters: ITopMenuFilters;
    availableFilterOptions: {
        travelModes: ISelectOption[];
        countries: ISelectOption[];
    };
    onFiltersChange: (partialValue: Partial<ITopMenuFilters>) => void;
};

export const TopMenuFilters = ({ filters, availableFilterOptions, onFiltersChange }: TProps) => {
    const travelModeOptions = useMemo(
        () =>
            availableFilterOptions.travelModes.map(option => ({
                ...option,
                label: (
                    <>
                        <FontAwesomeIcon icon={TRAVEL_MODE_ICONS[option.value]} />
                        <span className="travel-mode-text">{option.label}</span>
                    </>
                ),
            })),
        [availableFilterOptions.travelModes],
    );

    return (
        <div className="top-menu-filters">
            <StlSelect
                value={filters.country}
                options={availableFilterOptions.countries}
                onChange={option => onFiltersChange({ country: option?.value as TOrgCountryKey })}
                customComponents={{ DropdownIndicator }}
            />
            <div className="stl-vertical-divider" />
            <StlToggleButtonGroup
                value={filters.travelMode || ""}
                onChange={(e, value) => onFiltersChange({ travelMode: value })}
            >
                {travelModeOptions.map(travelMode => (
                    <StlToggleButton key={travelMode.value} id={travelMode.value}>
                        {travelMode.label}
                    </StlToggleButton>
                ))}
            </StlToggleButtonGroup>
        </div>
    );
};
